import React from 'react';
import RConfetti from 'react-confetti';
import onSchedule from '../../HOCs/onSchedule';
import { useWindowDimensions } from '../../hooks';

function Confetti(props: any) {
  const [width, height] = useWindowDimensions();

  return (
    <RConfetti
      data-confetti-event="New Year, 2021!"
      width={width}
      height={height}
      numberOfPieces={1000}
      recycle={false}
      {...props}
    />
  );
}

export default onSchedule(Confetti, '01 January 2021');
