import styled from '@emotion/styled';
import { IBannerProps } from './Banner.types';

export const styles = {
  fontSize: '16px',
  color: 'var(--text)',
  lineHeight: '1.55556',
  // letterSpacing: '-0.05em',
};

export const bgColorPalette = {
  success: 'rgb(198, 246, 213)',
  error: 'rgb(254, 215, 215)',
  warn: 'rgb(254, 235, 200)',
  info: 'rgb(190, 227, 248)',
};

export const borderColorPalette = {
  success: 'rgb(56, 161, 105)',
  error: 'rgb(229, 62, 62)',
  warn: 'rgb(221, 107, 32)',
  info: 'rgb(49, 130, 206)',
};

export const BannerContainer = styled.div<{ variant: IBannerProps['variant'] }>(
  (props) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    position: 'fixed',
    top: '0',
    padding: '0.5rem',
    background: bgColorPalette[props.variant],
    borderTop: '4px solid',
    borderColor: borderColorPalette[props.variant],
    overflow: 'hidden',
  })
);

export const Title = styled.p`
  margin: 0;
  font-weight: 600;
  margin-right: 0.75rem;
  line-height: ${styles.lineHeight};
  font-size: ${styles.fontSize};
  color: ${styles.color};
`;

export const Message = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: ${styles.fontSize};
  line-height: ${styles.lineHeight};
  letter-spacing: ${styles.letterSpacing};

  p {
    color: ${styles.color};
  }

  a {
    transition: border-bottom 0.25s ease-in-out;
    border-bottom: 2px solid rgba(59, 57, 52, 0.4);
    text-decoration: none;
    color: ${styles.color};

    &:hover {
      border-bottom: 2px solid rgba(59, 57, 52, 0.8);
    }
  }
`;
