import React from 'react';

/**
 * HOC to render component on schedule.
 *
 * @param {React.ComponentType} Component Component to be scheduled.
 * @param {string} scheduledDate Schedule date.
 */
export default function onSchedule(
  Component: React.ComponentType,
  scheduledDate: string
) {
  const isSchedule = () => {
    if (!scheduledDate) throw new Error('Schedule date is not provided.');

    const _scheduledDate = new Date(Date.parse(scheduledDate)).toDateString();
    const _now = new Date().toDateString();
    return _scheduledDate === _now;
  };

  return class extends React.Component {
    render() {
      if (!isSchedule()) {
        return <></>;
      }

      return <Component {...this.props} />;
    }
  };
}
