import React, { Fragment, FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './homepage.module.scss';

const Fade = require('react-reveal/Fade');

const reactRevealAnimationDistance = '30px';
const avatarURL = `https://avatars.githubusercontent.com/kasipavankumar?s=200`;

const Introduction: FC = (): JSX.Element => {
  return (
    <Fragment>
      <section className={styles.homepage_introduction}>
        <Avatar avatarClassName={styles.homepage_avatar} />
        <Fade ssrReveal top cascade distance={reactRevealAnimationDistance}>
          <section className={styles.homepage_introduction_content}>
            <p className={styles.introduction_first_line} />
            <p className={styles.introduction_second_line} />
            <p className={styles.introduction_third_line} />
          </section>
        </Fade>
      </section>
    </Fragment>
  );
};

// ----------------------------------

interface AvatarProps {
  avatarClassName: string;
}

const Avatar: FC<AvatarProps> = ({ avatarClassName }): JSX.Element => {
  const data = useStaticQuery(graphql`
    query getAvatar {
      file(name: { eq: "dkpk-avatar" }) {
        publicURL
      }
    }
  `);

  return (
    <Fragment>
      <Fade ssrReveal right distance={reactRevealAnimationDistance}>
        <img
          alt="avatar"
          className={avatarClassName}
          src={data.file.publicURL}
        />
      </Fade>
    </Fragment>
  );
};

// ----------------------------------

export default Introduction;
