import React, { FC } from 'react';

import Introduction from './Introduction';
import Nav from './Nav';
import Confetti from '../Confetti';
import styles from './homepage.module.scss';

/** Homepage component. */
const Homepage: FC = (): JSX.Element => {
  return (
    <div className={styles.homepage}>
      <Introduction />
      <div className={styles.seperator} />
      <Nav />
      <Confetti />
    </div>
  );
};

export default Homepage;
