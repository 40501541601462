import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

import { useApi } from '../../../hooks';
import { IBannerProps } from './Banner.types';
import { BannerContainer, Message, Title } from './Banner.styles';

const Fade = require('react-reveal/Fade');

export default function Banner({
  isMarkdown = true,
  variant = 'success',
}: IBannerProps) {
  const { data, hasErrors, isLoading } = useApi('banner-messages');

  if (isLoading || hasErrors) {
    return null;
  }

  const {
    banner: { id, title, message, severity },
    show_banner,
  } = data;

  if (!show_banner) {
    return null;
  }

  return (
    <Fade top distance="20px">
      <BannerContainer
        id={id}
        key={id}
        role="alert"
        data-inspired-by="@chakra-ui"
        className="@dkpk.ui-banner"
        variant={severity || variant}
      >
        <Title className="@dkpk.ui-banner-title">{title}</Title>
        <Message
          className="@dkpk.ui-banner-message"
          dangerouslySetInnerHTML={{
            __html: isMarkdown
              ? DOMPurify.sanitize(
                  marked(message, { gfm: true, smartypants: true })
                )
              : message,
          }}
        />
      </BannerContainer>
    </Fade>
  );
}
