import React from 'react';
import Layout from '../components/layout';
import Homepage from '../components/Homepage';
import Banner from '../components/core/Banner';
import page from '../HOCs/page';

const App = () => {
  return (
    <>
      <Banner />
      <Layout>
        <Homepage />
      </Layout>
    </>
  );
};

export default page(App, 'home');
